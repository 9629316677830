@import '../components.scss';

.Button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 58px;
  padding: 5px 0;
  color: $color-white;
  text-align: center;
  text-transform: uppercase;
  font-size: 19px;
  background-color: $color-blue;
  border-radius: calc(58px / 2);
  text-decoration: none;
  transform-origin: center;
  transform: scale3d(1,1,1);
  transition: transform .15s ease-in-out, background-color .15s ease-in-out;
  @include font-semibold;

  &:hover {
    background-color: darken($color-blue, 5%);
  }
  &:active {
    transform: scale3d(0.95,0.95,1);
  }
}
