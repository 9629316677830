@import '../components.scss';

.SectionHome {
  max-width: 1084px;
  padding: 32px 32px 50px;
  text-align: left;

  @include respond-to('lg') {
    padding: 32px 57px 68px;
  }
}
