@import '../components.scss';

.Nav {
  .NavHamburger {
    position: fixed;
    top: 0;
    right: 0;
    outline: 0;
    z-index: $z-index-nav-btn;
  }
  nav {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $color-grayblue;
    z-index: $z-index-nav;
    overflow: hidden;

    .scroll-area {
      overflow: auto;
      height: 100vh;
      padding-top: 28px;
      padding-bottom: 50px;
    }

    ul {
      padding: 0;
      margin: 0;
      border-top: 1px solid $color-blue;

      li {
        a {
          display: block;
          width: 100%;
          height: 58px;
          text-transform: uppercase;
          color: $color-white;
          text-decoration: none;
          text-align: center;
          font-size: 23px;
          line-height: 58px;
          border-bottom: 1px solid $color-blue;
        }
      }
    }
    .logo {
      img {
        width: 100%;
        height: 100%;
      }
    }
    .ButtonReservationBar {
      padding: 24px 0;

      @include respond-to('lg') {
        padding: 26px 0;
        border-bottom: 1px solid $color-blue;
      }
    }
    .Footer {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
    }

    &.mobile {
      transform: translate3d(0, -100%, 0);
      transition: transform .3s;

      &.active {
        transform: translate3d(0, 0, 0);
      }

      a.logo {
        display: block;
        width: 130px;
        height: 126px;
        margin: 0 auto 22px;
      }
    }
    &.desktop {
      width: 281px;
      padding-top: 34px;

      a.logo {
        display: block;
        width: 193px;
        height: 187px;
        margin: 0 auto 36px;
      }

      ul {
        li {
          a {
            position: relative;
            height: 65px;
            font-size: 19px;
            line-height: 65px;

            &:after {
              content: '';
              display: block;
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              width: 10px;
              background-color: $color-blue;
              transform: scale3d(0,1,1);
              transform-origin: center right;
              transition: transform .3s ease-in-out;
            }

            &.active {
              &:after {
                transform: scale3d(1,1,1);
              }
            }
          }
        }
      }
    }
  }
}
