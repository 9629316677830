@import '../components.scss';

.MenuNavDesktop {
  height: 100%;
  width: 248px;

  .fixed {
    position: fixed;
    width: 248px;
    padding-left: 60px;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      a {
        display: block;
        font-size: 18px;
        margin-bottom: 10px;
        color: $color-black;
        text-decoration: none;
        transform: scale3d(1,1,1);
        transition: color .3s, transform .3s;
        transform-origin: center left;
        @include font-semibold;

        &.active {
          color: $color-blue;
          //transform: scale3d(1.11,1.11,1);
        }
      }
    }
  }
}
