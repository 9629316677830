@import '../components.scss';

.HomeMenu {
  width: 100%;
  background-color: $color-bglight;

  .SectionHomeHeader{
    .line {
      height: 0;

      @include respond-to('lg') {
        height: 1px;
      }
    }
  }

  a {
    display: block;
    text-decoration: none;
    white-space: nowrap;
    overflow: hidden;
    @include font-semibold;

    &.mobile {
      width: 170px;
      height: 42px;
      padding: 10px 0;
      background-color: $color-blue;
      border-radius: calc(58px / 2);
      margin: 0 5px 15px;
      color: $color-white;
      text-align: center;
      font-size: 18px;
      transform: scale3d(1,1,1);
      transition: transform .15s ease-in-out, background-color .15s ease-in-out;

      &:hover {
        background-color: darken($color-blue, 5%);
      }
      &:active {
        transform: scale3d(0.95,0.95,1);
      }

      span {
        display: none;
      }
    }
    &.desktop {
      display: flex;
      align-items: center;
      min-width: 50%;
      font-size: 30px;
      line-height: 50px;
      color: $color-grayblue;

      &:hover {
        span {
          transform: translate3d(4px, 0, 0);
        }
      }

      @include respond-to('xl') {
        min-width: 33.333%;
        font-size: 30px;
        line-height: 70px;
      }

      span {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 25px;
        height: 25px;
        margin: 4px 10px 0 10px;
        border-radius: 50%;
        background-color: $color-blue;
        transform-origin: center;
        transform: translate3d(0,0,0);
        transition: transform .15s ease-in-out;

        img {
          display: block;
          width: 8px;
          height: 13px;
        }
      }
    }
  }

  .sections {
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    // justify-content: space-between;

    @include respond-to('lg') {
      justify-content: flex-start;

    }
  }
}
