@use "sass:math";
@import '../components.scss';

.HomeReviews {
  width: 100%;
  padding: 0;
  background-color: $color-grayblue;

  & > div {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }

  .image {
    position: relative;
    width: 100%;
    margin-bottom: 7px;

    &:before {
      content: '';
      display: block;
      position: relative;
      width: 100%;
      padding-top: math.div(799, 1125) * 100%;

      @include respond-to('md') {
        padding-top: math.div(505, 542) * 100%;
      }
    }

    @include respond-to('md') {
      width: 33.3333%;
      margin: 0;
    }
    @include respond-to('xl') {
      width: 25%;
    }

    &.bottom {
      margin-top: 7px;
      margin-bottom: 14px;

      @include respond-to('md') {
        margin: 0;
      }
    }

    img {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: auto;
    }
  }

  .Review {
    width: 100%;
    margin: 7px 0;

    @include respond-to('md') {
      width: 33.3333%;
      margin: 0;
    }
    @include respond-to('xl') {
      width: 25%;
    }
  }
}
