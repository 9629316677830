@use "sass:math";
@import './components.scss';
@import '../node_modules/@glidejs/glide/src/assets/sass/glide.core';

$page-transition-time: 500ms;
$page-transition-time-half: math.div($page-transition-time, 2);

.App {
  display: flex;
  min-height: 100vh;
  max-width: $site-max-width;
  flex-direction: column;

  @include respond-to('lg') {
    flex-direction: row;
    height: 100vh;
  }

  .navigation {
    width: 100%;

    @include respond-to('lg') {
      width: 281px;
    }
  }
  .content {
    position: relative;
    width: 100%;

    @include respond-to('lg') {
      width: calc(100% - 281px);
      height: 100vh;
    }

    // Pages
    & > div > div {
      position: absolute;
      top: 0;
      //position: relative;
      width: 100%;

      @include respond-to('lg') {
        position: absolute;
        top: 0;
      }
    }
  }

  .fade-enter {
    opacity: 0;
  }
  .fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity $page-transition-time-half ease-in-out $page-transition-time-half;
  }
  .fade-exit {
    opacity: 1;
  }
  .fade-exit.fade-exit-active {
    opacity: 0;
    transition: opacity $page-transition-time-half ease-in-out;
  }
}
