@import '../../components.scss';

.modal-portal {
}
.modal-overlay {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: $z-index-modal;
  padding: 10px;
  opacity: 0;
  transition: opacity 600ms ease-in-out;
  background-color: rgba(0,0,0, .75);
}
.ReactModal__Overlay--after-open {
  opacity: 1;
}
.ReactModal__Overlay--before-close {
  opacity: 0;
}
.modal-content {
  position: relative;
  min-width: 320px;
  min-height: 200px;
  max-width: 100vw;
  max-height: 100vh;
  background: $color-bglight;
  overflow: auto;
  outline: none;
}
