@import '../components.scss';

.SectionHomeHeader {
  position: relative;
  padding-bottom: 21px;
  margin-bottom: 17px;

  &.on-dark {
    h1,
    h2 {
      color: $color-textlight;
    }
  }
  &.on-light {
    h1,
    h2 {
      color: $color-grayblue;
    }
  }

  h1,
  h2 {
    @include font-light;
    font-size: 23px;
    line-height: 28px;
    text-transform: uppercase;
    margin: 0;

    @include respond-to('md') {
      //font-size: 33px;
      //line-height: 39px;
    }

    @include respond-to('lg') {
      font-size: 43px;
      line-height: 52px;
    }
  }

  .subheading {
    margin: 6px 0 0;
    color: $color-blue;
    font-size: 18px;
    line-height: 22px;

    @include respond-to('lg') {
      font-size: 23px;
      line-height: 28px;
    }
  }

  .line {
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    height: 1px;
    width: 195px;
    background-color: $color-blue;

    @include respond-to('lg') {
      width: 310px;
    }
  }
}
