@import '../components.scss';

.ButtonSocial {
  display: flex;
  width: 50px;
  height: 50px;
  justify-content: center;
  align-items: center;
  background-color: $color-blue;
  border-radius: calc(50px / 2);
  text-decoration: none;
  transform-origin: center;
  transform: scale3d(1,1,1);
  transition: transform .15s ease-in-out, background-color .15s ease-in-out;

  &:hover {
    background-color: darken($color-blue, 5%);
  }
  &:active {
    transform: scale3d(0.95,0.95,1);
  }

  img {
    display: block;
  }
}
