// Breakpoints
$breakpoint-xs: 0;
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;
$breakpoint-max: 1600px;

// Misc
$site-max-width: 1820px;

// Colors
$color-black: #000;
$color-white: #fff;
$color-grayblue: #292C30;
$color-blue: #72A1CF;
$color-gray: #66666E;
$color-bglight: #E6E6E9;
$color-textlight: #FCF7F7;

// z-index
$z-index-header: 1000;
$z-index-nav: 1001;
$z-index-nav-btn: 1002;
$z-index-modal: 1100;
$z-index-preloader: 1200;

// Fonts
$font-default: 16px;
