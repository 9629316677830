@import '../components.scss';

.Home {
  position: relative;
  width: 100%;

  .ButtonReservationBar {
    background-color: $color-gray;

    @include respond-to('lg') {
      display: none;
    }
  }
  .ButtonSocialBar {

    @include respond-to('lg') {
      display: none;
    }
  }
  .Footer {
    @include respond-to('lg') {
      padding-left: 58px;
      text-align: left;
    }
  }
}
