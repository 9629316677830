@import '../components.scss';

.ButtonSocialBar {
  display: flex;
  padding: 24px 0;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: $color-grayblue;

  .ButtonSocial {
    margin: 0 6px;
  }
}
