@import '../components.scss';

.HomeAbout {
  width: 100%;
  background-color: $color-grayblue;

  @include respond-to('lg') {
    background-color: $color-bglight;
  }

  .desc p {
    color: #FCF7F7;
    font-size: 18px;
    line-height: 22px;
    padding-right: 30px;
    margin-bottom: 54px;

    &:first-child {
      margin-bottom: 0;
    }

    @include respond-to('md') {
      font-size: 19px;
      line-height: 23px;
    }
    @include respond-to('lg') {
      color: $color-grayblue;
    }
    @include respond-to('xl') {
      color: $color-grayblue;
      padding-right: 147px;
    }
  }
}
