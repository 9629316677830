@import '../components.scss';

.ButtonReservationBar {
  padding: 20px 0;
  background-color: transparent;

  .ButtonReservation {
    margin: 0 auto;
  }
}
