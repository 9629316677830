@import '../components.scss';

.Footer {
  padding: 10px 0;
  background-color: $color-grayblue;

  div,
  a {
    display: block;
    text-align: center;
    font-size: 12px;
    line-height: 15px;
    color: $color-blue;
    text-decoration: none;

    @include respond-to('md') {
      text-align: left;
    }
  }
  a:hover {
    text-decoration: underline;
  }
}
