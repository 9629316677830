@import '../components.scss';

.ImageCarousel {
  position: relative;
  width: 100%;
  padding-top: calc((729 / 1125) * 100%);

  @include respond-to('lg') {
    padding-top: calc((505 / 1084) * 100%);
  }

  .ImageCarouselGlide {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    font-size: 0;
    overflow: hidden;
    
    .glide__track {
      position: relative;
      width: 100%;
      height: 100%;
  
      .glide__slides {
        position: relative;
        width: 100%;
        height: 100%;
  
        .glide__slide {
          position: relative;
          width: 100%;
          height: 100%;

          img {
              display: block;
              width: 100%;
              height: auto;
            }
        }
      }
    }
  }
}
