@import '../components.scss';

.Review {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  padding: 24px 20px 28px;
  background-color: $color-gray;

  @include respond-to('md') {
    width: 33.3333%;
  }
  @include respond-to('xl') {
    width: 25%;
  }

  .rating {
    display: flex;
    justify-content: center;
    margin-bottom: 12px;

    img {
      width: 23px;
      height: 22px;
      margin-right: 10px;
    }
  }

  p {
    max-width: 240px;
    margin: 0;
    color: $color-white;
    font-size: 18px;
    line-height: 22px;

    @include respond-to('md') {
      font-size: 16px;
      line-height: 20px;
    }
  }

  .second {
    margin-top: 15px;
  }
}
