/**
 * "Lato" font mixins
 *
 * CSS that must be used in head tag of HTML
 *  <link href="https://fonts.googleapis.com/css?family=Dosis:300,600&display=swap" rel="stylesheet">
 */
@mixin font-light {
  font-family: 'Dosis', sans-serif;
  font-weight: 300;
}
@mixin font-semibold {
  font-family: 'Dosis', sans-serif;
  font-weight: 600;
}
