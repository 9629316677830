@import '../../components.scss';

.ModalNotice {
  max-width: 366px;
  padding: 38px 37px;
  font-size: 19px;
  line-height: 1.2;
  overflow: hidden;

  .heading {
    text-align: center;
    text-transform: uppercase;
    @include font-semibold;
  }
  .buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 40px;
  }
}
