@import '../components.scss';

.Menu {
  position: relative;
  background-color: $color-bglight;
  @include respond-to('lg') {
    padding-top: 84px;
  }

  .menu-header-pad {
    height: 84px;
    background-color: $color-grayblue;
    @include respond-to('lg') {
      display: none;
    }
  }

  .MenuFlags {
    padding: 12px 32px 2px;

    @include respond-to('lg') {
      position: fixed;
      z-index: $z-index-header;
      top: 0;
      padding: 42px 60px 10px;
    }
  }

  .menu-side-by-side {
    display: flex;
    flex-direction: row;

    .menu-nav {
      flex: 0 1;
      width: 248px;
      height: 100%;
    }
  }

  .sections {
    padding: 12px 32px;

    @include respond-to('lg') {
      //position: absolute;
      padding: 0 60px 42px;
      border-left: 1px solid $color-blue;
    }

    .section {
      padding-top: 10px;
      margin-bottom: 14px;

      .heading {
        margin: 0 0 20px;
      }

      h2 {
        margin: 0;
        color: $color-blue;
      }

      &:after {
        content: "";
        display: block;
        width: 310px;
        height: 10px;
        border-bottom: 1px solid $color-blue;
      }

      &:last-child {
        &:after {
          display: none;
        }
      }

      .note {
        max-width: 400px;
        font-size: 15px;
        @include font-light;
      }
    }

    .item {
      position: relative;
      max-width: 400px;
      margin-bottom: 15px;

      &.cols2 {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        max-width: 400px;

        div {
          width: 50%;
          min-width: 155px;
          margin-bottom: 5px;
          font-size: 15px;
          white-space: nowrap;
          overflow: hidden;
          @include font-light;
        }
      }

      h3 {
        margin: 0;
        font-size: 18px;
      }
      p {
        margin: 0;
        font-size: 15px;
      }
      img {
        width: 12px;
        height: 12px;

        &.new {
          display: block;
          position: absolute;
          top: -1px;
          left: -26px;
          width: 22px;
          height: 22px;
        }
      }
    }
  }

  .ButtonReservationBar {
    background-color: $color-gray;
  }
  .Footer {
    @include respond-to('lg') {
      padding-left: 58px;
      text-align: left;
    }
  }
}
