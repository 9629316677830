@import '../components.scss';

.MenuFlags {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
  background-color: $color-bglight;

  .item {
    display: flex;
    flex-direction: row;
    height: 21px;
    margin-right: 10px;
    margin-bottom: 10px;
    line-height: 21px;
    font-size: 15px;

    @include respond-to('lg') {
      font-size: 16px;
    }

    img {
      width: 21px;
      height: 21px;
      margin-right: 5px
    }
    span {
      white-space: pre;
    }
  }
}
