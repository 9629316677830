@import '../components.scss';

$preloader-background-color: $color-grayblue;
$preloader-color: $color-blue;

.Preloader {
  position: fixed;
  z-index: $z-index-preloader;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: $preloader-background-color;
  opacity: 0;
  transition: opacity 1s;
  pointer-events: none;

  &.show {
    opacity: 1;
    pointer-events: auto;
  }

  .spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 70px;
    transform: translate(-50%, -50%);
    text-align: center;

    & > div {
      width: 18px;
      height: 18px;
      background-color: $preloader-color;

      border-radius: 100%;
      display: inline-block;
      -webkit-animation: preloader-animation 1.4s infinite ease-in-out both;
      animation: preloader-animation 1.4s infinite ease-in-out both;
    }

    .bounce1 {
      -webkit-animation-delay: -0.32s;
      animation-delay: -0.32s;
    }

    .bounce2 {
      -webkit-animation-delay: -0.16s;
      animation-delay: -0.16s;
    }
  }

  @-webkit-keyframes preloader-animation {
    0%, 80%, 100% { -webkit-transform: scale(0) }
    40% { -webkit-transform: scale(1.0) }
  }

  @keyframes preloader-animation {
    0%, 80%, 100% {
      -webkit-transform: scale(0);
      transform: scale(0);
    } 40% {
      -webkit-transform: scale(1.0);
      transform: scale(1.0);
    }
  }
}
