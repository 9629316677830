
* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}
body {
  margin: 0;
  @include font-light;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $color-grayblue;
}
