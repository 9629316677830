@import '../components.scss';

.Header {
  position: relative;
  width: 100%;

  @include respond-to('lg') {
    display: none;
  }

  .bar {
    position: fixed;
    top: 0;
    left: 0;
    z-index: $z-index-header;
    width: 100%;
    height: 84px;
    overflow: hidden;
    background-color: $color-grayblue;
    opacity: 0;
    transition: opacity .3s;

    &.active {
      opacity: 1;
    }

    .bar-logo {
      display: block;
      position: absolute;
      top: 16px;
      left: 28px;
      width: 53px;
      height: 51px;

      img {
        width: 100%;
        height: 100%;
      }
    }
    .title {
      display: block;
      width: 100%;
      height: 84px;
      font-size: 23px;
      line-height: 84px;
      color: $color-white;
      text-transform: uppercase;
      text-align: center;
    }
  }
}
