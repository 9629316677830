@import '../components.scss';

.HomeLocationsAndHours {
  width: 100%;
  background-color: $color-grayblue;

  .SectionHome {
    padding-bottom: 0;

    @include respond-to('lg') {
      padding-bottom: 40px;
    }
  }

  a {
    display: block;
    width: 100%;
    max-width: 320px;
    margin-bottom: 33px;

    @include respond-to('lg') {
      max-width: 545px;
      margin: 30px 0 58px;
    }
    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }

  p {
    font-size: 18px;
    line-height: 22px;
    color: $color-white;

    @include respond-to('lg') {
      font-size: 23px;
      line-height: 29px;
      margin: 0;
    }
  }
}
