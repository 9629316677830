@import '../components.scss';

.HomeHeader {
  position: relative;
  width: 100%;
  height: 321px;
  max-height: 321px;
  background-color: $color-grayblue;
  
  @include respond-to('lg') {
    display: none;
  }

  .logo {
    display: block;
    position: absolute;
    top: 54px;
    left: 50%;
    width: 231px;
    height: 224px;
    margin-left: (231px * -.5);
  }
}
