@import '../components.scss';

.MenuNavMobile {
  width: 100%;
  height: 69px;
  background-color: $color-grayblue;

  .bar {
    position: fixed;
    top: 84px;
    left: 0;
    z-index: $z-index-header;
    width: 100%;
    height: 69px;
    background-color: $color-bglight;
    border-bottom: 14px solid $color-grayblue;

    button {
      position: absolute;
      top: 0;
      width: 55px;
      height: 55px;
      padding: 0;
      background-color: $color-blue;
      border: 0;
      outline: 0;

      &.left {
        left: 0;
      }
      &.right {
        right: 0;
      }

      img {
        width: 15px;
        height: 23px;
      }
    }
    .labels {
      display: flex;
      width: 100%;
      height: 55px;
      padding: 0 55px;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      h2 {
        margin: 0;
        text-align: center;
        font-size: 18px;
      }
      .subheading {
        text-align: center;
        font-size: 15px;
      }
    }
  }
}
