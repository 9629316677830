// sass-lint:disable space-around-operator

/**
 * Media query for responsive breakpoints
 */
@mixin respond-to($breakpoint) {
  @if $breakpoint == "sm" {
    @media only screen and (min-width: $breakpoint-sm) {
      @content;
    }
  }
  @else if $breakpoint == "md" or $breakpoint == "tablet" {
    @media only screen and (min-width: $breakpoint-md) {
      @content;
    }
  }
  @else if $breakpoint == "lg" or $breakpoint == "desktop" {
    @media only screen and (min-width: $breakpoint-lg) {
      @content;
    }
  }
  @else if $breakpoint == "xl" or $breakpoint == "large-desktop" {
    @media only screen and (min-width: $breakpoint-xl) {
      @content;
    }
  }
  @else if $breakpoint == "mobile-only" {
    @media only screen and (max-width: $breakpoint-md - 1) {
      @content;
    }
  } @else if $breakpoint == "tablet-only" {
    @media only screen and (min-width: $breakpoint-md) and (max-width: $breakpoint-lg - 1) {
      @content;
    }
  }
}

/**
 * Clearfix
 */
@mixin clearfix {
  &::after {
    display: table;
    clear: both;
    content: "";
  }
}

/**
 * Make a line of text ellipsis-ize when text becomes too long for the width
 *  of the parent container.
 */
@mixin overflow-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
